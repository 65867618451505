import React from "react"
import styled from "styled-components"
import { fetchPrivacyMarkdown } from "../../hooks/fetchPrivacyMarkdown"
import SEO from "../../components/seo"
import { slug } from "../../utils/slug"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1%;
`

const PrivacyContainer = styled.div`
  h1 {
    font-weight: bold;
    font-size: 18px;
  }

  h2 {
    font-weight: bold;
    margin: 2% 0%;
  }

  p {
    margin: 1% 0%;
  }

  strong {
    font-weight: bold;
  }
`

const Terms = () => {
  const { markdownRemark } = fetchPrivacyMarkdown()
  const { html, frontmatter } = markdownRemark

  return (
    <Container>
      <SEO title={"Privacy Policy"} pagePath={slug("privacy")} />
      <PrivacyContainer dangerouslySetInnerHTML={{ __html: html }} />
      <span>DATE: {frontmatter.date}</span>
    </Container>
  )
}

export default Terms
